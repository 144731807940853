import { useState } from "react";
import { DashCameraViewer } from "./Dash";
import { HlsCameraViewer } from "./Hls";
import { STREAMING_TYPE } from "./streaming-enum";



export const Monitor = () => {
  const [streamingType, setStreamingType] = useState(STREAMING_TYPE.hls)
  // switch (streamingType) {
  //   case STREAMING_TYPE.hls:
  //     return <HlsCameraViewer streamingType={streamingType}  setStreamingType={setStreamingType}/>;
  //   case STREAMING_TYPE.dash:
  //     return <DashCameraViewer streamingType={streamingType} setStreamingType={setStreamingType}/>;
  //   default:
  //     return <DashCameraViewer streamingType={streamingType} setStreamingType={setStreamingType}/>;
  // }

  return <HlsCameraViewer streamingType={streamingType}  setStreamingType={setStreamingType}/>;
};
