export const label_studio_interfaces = [
    "submit",
    "panel",
    "controls",
    "topbar",
    "infobar",
    "instruction",
    "side-column",
    "annotations:menu",
    // "annotations:delete",
    "predictions:menu",
    "annotations:history",
    "annotations:current",
    "edit-history",
  ]

  export const label_studio_interfaces2 = [
    "submit",
    "panel",
    "controls",
    "topbar",
    "infobar",
    "instruction",
    // "side-column",
    "annotations:menu",
    // "annotations:delete",
    "predictions:menu",
    "annotations:history",
    "annotations:current",
    "edit-history",
  ]