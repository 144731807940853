import React from "react";
import {
  HamburgerMenuIcon,
  PlusIcon,
  ChevronDownIcon,
  MixerHorizontalIcon,
  AvatarIcon,
  GearIcon,
  ArrowRightIcon,
} from "@radix-ui/react-icons";

import { DropdownMenu } from "..";
import { useStoreActions, useStoreState } from "../../store/easy-peasy/hooks";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../../assets/images/header/logo.png'

export const MainHeader = ({ onToggle }: any) => {
  const navigate = useNavigate();
  let user = useStoreState((store) => store.user);
  let logout = useStoreActions((store) => store.logout);
  const profile = [
    {
      id: "1",
      name: "top",
      path: "",
      component: (
        <div className="flex flex-col justify-center items-center p-4 text-center">
          {/* <div className="bg-yellow-500 rounded-full p-5">
            <p className="text-white">WA</p>
          </div> */}
          <div className="mt-3">
            <p className="text-white"> My workspace </p>
            <p className="text-white">{user.email}</p>
          </div>
        </div>
      ),
    },
    {
      id: "2",
      name: "Admin Console",
      path: "",
      icon: <MixerHorizontalIcon />,
    },
    {
      id: "3",
      name: "separater",
      path: "",
    },
    {
      id: "4",
      name: "Profile",
      path: "",
      icon: <AvatarIcon />,
    },
    {
      id: "5",
      name: "Setting",
      path: "",
      icon: <GearIcon />,
    },
    {
      id: "6",
      name: "separater",
      path: "",
    },
    {
      id: "7",
      name: "Logout",
      path: "",
      icon: <ArrowRightIcon />,
      logout: () => {
        logout();
        navigate("/auth");
      },
    },
  ];
  return (
    <div className="w-full flex items-center justify-between px-8 h-16 bg-primary border-b border-header-border shadow-lg">
      <div className="flex items-center space-x-6 text-white">
        <button
          onClick={onToggle}
          className="active:rounded-full p-2 bg-transparent active:bg-black active:opacity-80"
        >
          <HamburgerMenuIcon className="w-6 h-6" />
        </button>

        <img
          className="object-cover"
          src={Logo}
          width={130}
          height={60}
          alt="logo"
        />
        
      </div>

      <div className="flex items-center space-x-4 text-white">
        <div className="flex justify-between items-center space-x-6 text-white">
          <DropdownMenu
            innerStyle="min-w-[280px] flex flex-col  rounded-md bg-secondary shadow-xl z-100 m-2 py-2 bg-red-500"
            arrowStyle="fill-secondary"
            isArrowShow={false}
            data={profile}
          >
            <div className="flex items-center space-x-1">
              <div className="bg-yellow-500 rounded-full w-10 h-10 flex justify-center items-center">
                <span className="text-white uppercase">
                  {user.name.substring(0, 2)}
                </span>
              </div>
              <ChevronDownIcon className="w-5 h-5 text-header-text" />
            </div>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
};
