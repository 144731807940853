import React, { useCallback, useEffect, useRef, useState } from "react";
import LabelStudio from "@heartexlabs/label-studio";
import "@heartexlabs/label-studio/build/static/css/main.css";
import { useStoreState } from "../../store/easy-peasy/hooks";
import { AnnotationData, DynamicLabel } from "../../types";
import { extractLabelColors, formatLabel, getConfig } from "./config";
import { label_studio_interfaces } from "../../utils/labelStudio";
import { toast } from "react-toastify";
import { openLinkInNewTab } from "../../pages/settings/utils";
import { useMutation } from "react-query";
import { optimisticOptions, putRequest, queries } from "../../react-query";

interface LabelStudioUIProps {
  imageLocation: string;
  categories: any;
  dynamicLabels: DynamicLabel[];
  setDynamicLabels: (config: DynamicLabel[]) => void;
  handleAction: any;
  annotations: any;
  setAnnotations: any;
  taskId: string;
  instructionLink: string;
}

export const ReLabelStudioUI: React.FC<LabelStudioUIProps> = ({
  imageLocation,
  dynamicLabels,
  setDynamicLabels,
  categories,
  handleAction,
  annotations,
  setAnnotations,
  taskId,
  instructionLink,
}) => {
  const user = useStoreState((store) => store.user);
  const rootRef = useRef<HTMLDivElement>(null);
  const labelStudioRef = useRef<any>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const categoryRef = useRef<any>(null);
  const [selectedCategory, setSelectedCategory] = useState<DynamicLabel>();
  const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);

  // Function to handle auto-scrolling
  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) {
      const labelPanel = scrollRef.current.querySelector(".lsf-labels-list");
      if (labelPanel) {
        labelPanel.scrollTop = labelPanel.scrollHeight;
      }
    }
  }, []);

  const onAddLabel = useCallback(() => {
    if (!selectedCategory || selectedSubCategories.length === 0) return;

    const label = formatLabel(selectedCategory, selectedSubCategories, {
      sort: "asc",
      separator: ", ",
    });

    const dl = Array.isArray(dynamicLabels)
      ? dynamicLabels.map((l: DynamicLabel) => l.label)
      : [];
    const updatedLabelsSet = new Set(dl);

    if (!updatedLabelsSet.has(label)) {
      setDynamicLabels([
        ...dynamicLabels,
        { label: label, background: selectedCategory.background },
      ]);
    } else {
      toast.error("Label already exists!");
    }

    clear();
  }, [
    selectedCategory,
    selectedSubCategories,
    dynamicLabels,
    setDynamicLabels,
  ]);

  const onReset = () => {
    setDynamicLabels([]);
    clear();
  };

  const clear = () => {
    categoryRef.current.value = "";
    setSelectedCategory({
      label: "",
      background: "",
    });
    setSelectedSubCategories([]);
  };

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const categoryId = event.target.value;
    const category = categories.find((cat: any) => cat.id === categoryId);
    setSelectedCategory({
      label: category.name,
      background: category.color,
    });
    setSelectedSubCategories([]);
  };

  const handleSubCategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const subCategoryId = event.target.value;
    if (event.target.checked) {
      setSelectedSubCategories((prev) => [...prev, subCategoryId]);
    } else {
      setSelectedSubCategories((prev) =>
        prev.filter((id) => id !== subCategoryId)
      );
    }
  };

  const handleAnnotation = useCallback(() => {
    if (labelStudioRef.current) {
      const LS = labelStudioRef.current;
      const annotation = LS.store.annotationStore.selected;
      const serializedAnnotations = annotation.serializeAnnotation();

      if (
        Array.isArray(serializedAnnotations) &&
        serializedAnnotations.length > 0
      ) {
        const labelColors = extractLabelColors(LS.store.config);
        const newAnnotations = serializedAnnotations?.map((item: any) => {
          const labelValue = item.value.rectanglelabels[0];
          const color = labelColors.get(labelValue);
          return {
            ...item,
            color,
          };
        });
        setAnnotations([{ result: newAnnotations }]);
      }
    }
  }, [setAnnotations]);

  // Initialize LabelStudio
  useEffect(() => {
    if (!rootRef.current) return;

    if (labelStudioRef.current) {
      labelStudioRef.current.destroy();
      labelStudioRef.current = null;
    }

    labelStudioRef.current = new LabelStudio(rootRef.current, {
      config: getConfig(dynamicLabels),
      interfaces: label_studio_interfaces,
      user: {
        pk: 1,
        firstName: user.name,
        lastName: "",
      },
      task: {
        id: Number("0x" + taskId.slice(0, 15).replace(/-/g, "")),
        data: {
          image: imageLocation,
        },
        annotations: annotations,
        predictions: [],
      },
      onLabelStudioLoad: (LS: any) => {
        if (!annotations.length) {
          const c = LS.annotationStore.addAnnotation({
            userGenerate: true,
          });
          LS.annotationStore.selectAnnotation(c.id);
        }
        setIsInitialized(true);
        setTimeout(scrollToBottom, 100);
      },
      onSubmitAnnotation: (LS: any, annotation: AnnotationData) => {
        setAnnotations((prev: any) => [...prev, annotation]);
        setTimeout(scrollToBottom, 100);
      },
      onUpdateAnnotation: (LS: any, annotation: AnnotationData) => {
        setAnnotations((prev: any) =>
          prev.map((a: any) => (a.id === annotation.id ? annotation : a))
        );
      },
      onDeleteAnnotation: (LS: any, annotation: AnnotationData) => {
        setAnnotations((prev: any) =>
          prev.filter((a: any) => a.id !== annotation.id)
        );
      },
    });

    return () => {
      if (labelStudioRef.current) {
        labelStudioRef.current.destroy();
        labelStudioRef.current = null;
      }
      setIsInitialized(false);
    };
  }, [
    imageLocation,
    dynamicLabels,
    taskId,
    user.name,
    annotations,
    setAnnotations,
  ]);

  // Add custom styles and mutation observer for dynamic content
  useEffect(() => {
    if (!isInitialized) return;

    const style = document.createElement("style");
    style.textContent = `
        .label-studio-root {
          display: flex !important;
          flex-direction: column !important;
          height: 100% !important;
          max-height: calc(100vh - 72px) !important;
        }
        .lsf-sidebar-tabs {
          height: auto !important;
          max-height: calc(100vh - 180px) !important;
          overflow-y: auto !important;
        }
        .lsf-labels-list {
          max-height: none !important;
          overflow-y: auto !important;
          scrollbar-width: thin !important;
        }
        .lsf-labels-list > div {
          height: auto !important;
        }
        .ls-wrap {
          flex: 1 !important;
          min-height: 0 !important;
          overflow: auto !important;
        }
        .ls-common-grid {
          overflow: visible !important;
        }
        .lsf-annotations {
          max-height: none !important;
        }
      `;
    document.head.appendChild(style);

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          scrollToBottom();
        }
      });
    });

    const labelsList = rootRef.current?.querySelector(".lsf-labels-list");
    if (labelsList) {
      observer.observe(labelsList, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      document.head.removeChild(style);
      observer.disconnect();
    };
  }, [isInitialized, scrollToBottom]);

  return (
    <div className="relative w-full z-0 flex flex-col h-[calc(100vh-72px)]">
      <div
        ref={scrollRef}
        className="flex-1 min-h-0 overflow-hidden"
        style={{
          height: "calc(100vh - 72px)", // Adjusted for additional controls
        }}
      >
        <div
          ref={rootRef}
          className="w-full h-full"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        />
      </div>

      <div className="h-[60px] flex-shrink-0 flex items-center justify-between px-6 bg-black">
        <div className="flex items-center space-x-4">
          <div className="relative">
            {selectedCategory && selectedCategory.label && (
              <div className="absolute bottom-full mb-5  shadow-lg p-4 z-[9999] w-[85.8vw] border bg-[#F3EEEA] b-2 border-yellow-600">
                <button
                  onClick={clear}
                  className="size-8 absolute -top-3 -right-3 rounded-full text-red-500  bg-[#F3EEEA]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </button>
                <h3 className="text-black">{selectedCategory.label}</h3>
                <div className="flex items-center space-x-4">
                  <div className="flex flex-row space-x-4">
                    {categories
                      .find((cat: any) => cat.name === selectedCategory.label)
                      ?.sub_categories?.map((sub: any) => (
                        <label
                          key={sub.id}
                          className="flex items-center text-black"
                        >
                          <input
                            type="checkbox"
                            value={sub.name}
                            checked={selectedSubCategories.includes(sub.name)}
                            onChange={handleSubCategoryChange}
                            className="mr-2"
                          />
                          {sub.name}
                        </label>
                      ))}
                  </div>
                  <button
                    onClick={onAddLabel}
                    className="text-black text-nowrap border border-black rounded-full px-4 py-1 shadow-lg"
                  >
                    Add Label
                  </button>
                </div>
              </div>
            )}

            <select
              ref={categoryRef}
              className="bg-white focus:outline-none"
              onChange={handleCategoryChange}
            >
              <option value="">Select Category</option>
              {categories?.map((category: any) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          <button onClick={onAddLabel} className="text-white text-nowrap">
            Add Label
          </button>

          <button className="text-white">Reset</button>
        </div>

        <div className="w-full flex justify-end space-x-4 items-center text-white">
          <button
            onClick={(e) => openLinkInNewTab(instructionLink, e)}
            className="text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
              />
            </svg>
          </button>
          <button
            onClick={() => handleAction("relabel", "confirm")}
            className={`bg-[#BAAC30] px-6 py-1 rounded-full cursor-pointer`}
          >
            Send for re-label
          </button>
          <button
            onClick={() => handleAction("reject", "remark")}
            className={` bg-[#FF0F00] px-6 py-1 rounded-full cursor-pointer`}
          >
            Reject
          </button>

          <button
            onClick={() => {
              handleAnnotation();
              handleAction("submit", "remark");
            }}
            className={` bg-[#1400FF] px-6 py-1 rounded-full cursor-pointer`}
          >
            Submit label
          </button>
        </div>
      </div>
    </div>
  );
};
