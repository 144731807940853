import { useCallback, useEffect, useMemo, useState } from "react";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  MultiSelect,
  MultiSelectDropdown,
  NormalSelect,
  ScrollArea,
  TextInput,
} from "../../../../components";
import {
  getRequest,
  optimisticOptions,
  putRequest,
  queries,
} from "../../../../react-query";
import { useMutation, useQuery } from "react-query";
import {
  reverseArray,
  reverseArrayWithIds,
  validateForm,
} from "../../../../utils";

const captures = [
  {
    id: "1",
    name: "Image",
    value: "image",
  },
  {
    id: "2",
    name: "Video",
    value: "video",
  },
];

export const UpdateCamera = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<any>({
    location: {
      label: "",
      value: "",
    },
    name: "",
    alias_name:"",
    ip_address: "",
    streamingKey: "",
    storageDuration: 20,
    instruction: {
      label: "",
      value: "",
    },
    captureOption: {
      label: "",
      value: "",
    },
    videoTrimTime: 7,
    remark: "",
    client: {
      label: "",
      value: "",
    },
    streaming_captains: [],
    streaming_quality_controllers: [],
    recordingLength: 900,
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  const [selectedCategories, setSelectedCategories] = useState<any>();
  const { data: cameraData = {}, isLoading: isCameraLoading }: any = useQuery(
    [queries.camera, id],
    () => getRequest(`/camera/${id}`),
    {
      enabled: !!id,
    }
  );

  // Destructuring Data
  const { data: camera = {} } = cameraData;

  const { data: clientData = [], isLoading: isClientLoading }: any = useQuery(
    [queries.clients],
    () => getRequest(`/client?role=ADMIN`)
  );
  // Destructuring Data
  const { data: clients = [] } = clientData;

  const [locations, setLocations] = useState([]);

  const { data: categoryData = [], isLoading: isCategoryLoading }: any =
    useQuery([queries.categories], () => getRequest(`/category`));
  // Destructuring Data
  const { data: categories = [] } = categoryData;

  const { data: safetyMembers = [], isSafetyLoading }: any = useQuery(
    [queries.users],
    () => getRequest(`/users`)
  );
  // Destructuring Data
  const { data: captains = [] } = safetyMembers;

  const { data: instructionsData = {} }: any = useQuery(
    [queries.instructions],
    () => getRequest(`/instruction`)
  );

  const { data: instructions = [] } = instructionsData;

  const onChangeHandler = (value: string | number, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: updateCamera } = useMutation(
    putRequest,
    optimisticOptions(queries.camera)
  );

  // Define validation rules
  const validationRules = {
    name: [{ required: true, message: "Camera name is required." }],
    ip_address: [{ required: true, message: "IP address is required." }],
    streamingKey: [{ required: true, message: "Streaming key is required." }],
    client: [{ required: true, message: "Client selection is required." }],
    location: [{ required: true, message: "Location selection is required." }],
    captureOption: [{ required: true, message: "Capture option is required." }],
    remark: [{ required: true, message: "Remark is required." }],
    recordingLength: [
      { required: true, message: "Recording length is required." },
    ],
    storageDuration: [
      { required: true, message: "Storage length is required." },
    ],
    instruction: [{ required: true, message: "Instruction is required" }],
    streaming_captains: [{ required: true, message: "select a captain" }],
    streaming_quality_controllers: [
      { required: true, message: "select a captain" },
    ],
    videoTrimTime: [
      {
        required: true,
        message: "Video trim time is required for video capture.",
      },
    ],
  };

  const onSubmitHandler = useCallback(
    (e: any) => {
      e.preventDefault();
      const formErrors = validateForm(formData, validationRules);
      setErrors(formErrors);
      // console.log("upload=====>", formData);

      if (Object.keys(formErrors).length === 0) {
        const payload = {
          name: formData.name,
          alias_name: formData.alias_name,
          status: "ACTIVE",
          storage_chunk_size: 1024,
          storage_time: Number(formData.storageDuration),
          is_video_capture:
            formData.captureOption.value === "video" ? true : false,
          video_trim_time: Number(formData.videoTrimTime),
          admin_client_id: formData.client.value,
          location_id: formData.location.value,
          categories: selectedCategories,
          remark: formData.remark,
          instruction_id: formData.instruction.value,
          streaming_key: formData.streamingKey,
          ip_address: formData.ip_address,
          recording_length: Number(formData.recordingLength),
          streaming_captain_id:
            formData.streaming_captains[0] &&
            formData.streaming_captains[0]["value"],
          streaming_quality_controller_id:
            formData.streaming_quality_controllers[0] &&
            formData.streaming_quality_controllers[0]["value"],
        };

        updateCamera({
          endPoint: `/camera/${id}`,
          payload: payload,
        });

        navigate(-1);
      }
    },
    [formData, selectedCategories, id]
  );

  const handleSelectCategories = (selectedCategories: any) => {
    setSelectedCategories(selectedCategories);
  };

  useEffect(() => {
    if (camera.id) {
      const {
        location,
        streaming_key,
        name,
        alias_name,
        is_video_capture,
        video_trim_time,
        remark,
        admin_client,
        preview_link,
        instruction,
        recording_length,
        streaming_captain,
        streaming_quality_controller,
      } = camera;

      const urlObj = new URL(preview_link);

      setFormData({
        location: {
          label: location.name,
          value: location.id,
        },
        name: name,
        alias_name: alias_name,
        ip_address: urlObj.host,
        streamingKey: streaming_key,
        storageDuration: 20,
        captureOption: is_video_capture
          ? { label: "Video", value: "video" }
          : { label: "Image", value: "iideo" },
        videoTrimTime: video_trim_time,
        remark: remark,
        instruction: {
          label: instruction.name,
          value: instruction.id,
        },
        client: {
          label: `${admin_client.company_name}`,
          value: admin_client.id,
        },
        streaming_captains: streaming_captain
          ? [{ label: streaming_captain.username, value: streaming_captain.id }]
          : [],
        streaming_quality_controllers: streaming_quality_controller
          ? [
              {
                label: streaming_quality_controller.username,
                value: streaming_quality_controller.id,
              },
            ]
          : [],
        recordingLength: recording_length,
      });

      const locations = clients?.find(
        (cli: any) => cli.id === admin_client.id
      )?.locations;
      setLocations(locations);

      let categories = reverseArrayWithIds(camera.sub_categories);
      setSelectedCategories(categories);
    }
  }, [camera, setFormData]);

  // Memoized filtered captains
  const streaming_captains = useMemo(
    () =>
      captains
        .filter((c: any) => c.roles.includes("STREAMING_CAPTAIN"))
        .map((c: any) => ({ label: c.username, value: c.id })),
    [captains]
  );
  const streaming_quality_controllers = useMemo(
    () =>
      captains
        .filter((c: any) => c.roles.includes("STREAMING_QUALITY_CONTROLLER"))
        .map((c: any) => ({ label: c.username, value: c.id })),
    [captains]
  );

  if (isClientLoading || isCategoryLoading || isCameraLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {formData && (
        <div className="bg-white w-full h-full p-8 space-y-4">
          <div className="flex w-full h-10 space-x-4 items-center">
            <ArrowLeftIcon
              onClick={() => navigate("/settings")}
              className="w-6 h-6 text-black cursor-pointer"
            />
            <span className="font-bold">Update Camera</span>
          </div>
          <form
            onSubmit={onSubmitHandler}
            className="w-full h-[calc(100vh-210px)] space-y-4 border border-gray-200 py-4 rounded"
          >
            <div className="px-4">
              <ScrollArea className="h-[calc(100vh-230px)] py-4">
                <div className="grid grid-cols-3 gap-8">
                  <TextInput
                    type="text"
                    placeholder=""
                    value={formData.name}
                    onChange={(e) => onChangeHandler(e.target.value, "name")}
                    theme="black"
                    error={errors.name}
                  >
                    <label htmlFor="name">Camera Name*</label>
                  </TextInput>

                  <TextInput
                    type="text"
                    placeholder=""
                    value={formData.alias_name}
                    onChange={(e) => onChangeHandler(e.target.value, "alias_name")}
                    theme="black"
                    error={errors.alias_name}
                  >
                    <label htmlFor="name">Alias Name</label>
                  </TextInput>


                  <TextInput
                    type="text"
                    placeholder=""
                    value={formData.streamingKey}
                    onChange={(e) =>
                      onChangeHandler(e.target.value, "streamingKey")
                    }
                    theme="black"
                    error={errors.streamingKey}
                  >
                    <label htmlFor="streamingKey">Streaming Key*</label>
                  </TextInput>

                  <TextInput
                    type="text"
                    placeholder=""
                    value={formData.ip_address}
                    onChange={(e) =>
                      onChangeHandler(e.target.value, "ip_address")
                    }
                    theme="black"
                    error={errors.ip_address}
                  >
                    <label htmlFor="applicationName">RTMP Server Domain*</label>
                  </TextInput>

                  <NormalSelect
                    theme="black"
                    keyField="value"
                    valueField="name"
                    className="border border-main text-nowrap"
                    data={captures}
                    error={errors.captureOption}
                    onValueChange={(value: string) =>
                      onChangeHandler(value, "captureOption")
                    }
                    defaultValue={formData.captureOption}
                    placeholder="Select"
                  >
                    <label htmlFor="remark">Capture</label>
                  </NormalSelect>

                  <TextInput
                    disabled={
                      formData.captureOption.value === "video" ? false : true
                    }
                    type="number"
                    placeholder=""
                    value={formData.videoTrimTime}
                    onChange={(e) =>
                      onChangeHandler(e.target.value, "videoTrimTime")
                    }
                    theme="black"
                    error={errors.videoTrimTime}
                  >
                    <label htmlFor="videoTrimTime">Video Trim Time</label>
                  </TextInput>

                  <div className="col-span-3">
                    <MultiSelect
                      theme="white"
                      keyField="value"
                      valueField="label"
                      data={streaming_captains}
                      error={errors.streaming_captain}
                      onValueChange={(value) =>
                        onChangeHandler(value, "streaming_captains")
                      }
                      value={formData.streaming_captains}
                      defaultValue={formData.streaming_captains}
                      placeholder="Streaming Captains"
                      isMulti
                    >
                      <label htmlFor="streaming_captains">
                        Streaming Captains*
                      </label>
                    </MultiSelect>
                  </div>

                  <div className="col-span-3">
                    <MultiSelect
                      theme="white"
                      keyField="value"
                      valueField="label"
                      data={streaming_quality_controllers}
                      error={errors.streaming_quality_controllers}
                      onValueChange={(value) =>
                        onChangeHandler(value, "streaming_quality_controllers")
                      }
                      value={formData.streaming_quality_controllers}
                      defaultValue={formData.streaming_quality_controllers}
                      placeholder="Streaming Quality Controllers"
                      isMulti
                    >
                      <label htmlFor="role">
                        Streaming Quality Controllers*
                      </label>
                    </MultiSelect>
                  </div>

                  

                  <div className="col-span-3">
                    <MultiSelectDropdown
                      className="border border-main text-nowrap"
                      theme="black"
                      categories={categories}
                      defaultValue={selectedCategories}
                      onSelect={handleSelectCategories}
                    >
                      <label htmlFor="Select">Categories*</label>
                    </MultiSelectDropdown>
                  </div>

                  <TextInput
                    type="number"
                    placeholder=""
                    value={formData.storageDuration}
                    onChange={(e) =>
                      onChangeHandler(e.target.value, "storageDuration")
                    }
                    theme="black"
                    error={errors.storageDuration}
                  >
                    <label htmlFor="storageDuration">
                      Storage Duration(days)*
                    </label>
                  </TextInput>

                  <NormalSelect
                    theme="black"
                    keyField="id"
                    valueField="company_name"
                    className="border border-main text-nowrap"
                    data={clients}
                    error={errors.client}
                    onValueChange={(cli: any) => {
                      let client = clients.find(
                        (client: any) => client.id === cli.value
                      );
                      setFormData({ ...formData, ["client"]: cli });
                      setLocations(client.locations);
                    }}
                    defaultValue={formData.client}
                    placeholder="Select"
                  >
                    <label htmlFor="remark">Clients Name*</label>
                  </NormalSelect>

                  <NormalSelect
                    theme="black"
                    disabled={formData.client.value ? false : true}
                    keyField="id"
                    valueField="name"
                    error={errors.location}
                    className="border border-main text-nowrap"
                    data={locations}
                    onValueChange={(value: string) =>
                      onChangeHandler(value, "location_id")
                    }
                    defaultValue={formData.location}
                    placeholder="Select"
                  >
                    <label htmlFor="remark">Camera Location*</label>
                  </NormalSelect>

                  <TextInput
                    type="number"
                    placeholder=""
                    value={formData.recordingLength}
                    onChange={(e) =>
                      onChangeHandler(e.target.value, "recordingLength")
                    }
                    theme="black"
                    error={errors.recordingLength}
                  >
                    <label htmlFor="recordingLength">Recording Length*</label>
                  </TextInput>

                  <NormalSelect
                    theme="black"
                    keyField="id"
                    valueField="name"
                    className="border border-main text-nowrap"
                    data={instructions}
                    error={errors.instruction}
                    onValueChange={(value: string) =>
                      onChangeHandler(value, "instruction")
                    }
                    defaultValue={formData.instruction}
                    placeholder="Select"
                  >
                    <label htmlFor="remark">Insruction Template</label>
                  </NormalSelect>

                  <TextInput
                    type="text"
                    placeholder=""
                    value={formData.remark}
                    onChange={(e) => onChangeHandler(e.target.value, "remark")}
                    theme="black"
                    error={errors.remark}
                  >
                    <label htmlFor="remark">Remarks</label>
                  </TextInput>
                </div>
              </ScrollArea>
            </div>

            <div className="w-full flex justify-end items-center">
              <button
                type="submit"
                className="rounded text-white bg-green px-6 py-2"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};
